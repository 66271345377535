<script>
import api from '@/command/api'
import DetailFormGroup from '../../../components/DetailFormGroup'
import { getAction } from '@/command/netTool'

export default {
  name: 'menuDetail',
  data() {
    return {
      ...api.command.getState(),
      detail: {},
      subordinate: [],
      isAddChildren: 1,
      dataId: null,
      svgFileList: [],
      imgIconObj: {
        audit: require('@/assets/imgicon/audit.png'),
        configuration: require('@/assets/imgicon/configuration.png'),
        goods: require('@/assets/imgicon/goods.png'),
        home: require('@/assets/imgicon/home.png'),
        marketing: require('@/assets/imgicon/marketing.png'),
        order: require('@/assets/imgicon/order.png')
      }
    }
  },
  mounted() {
    let { isAddChildren, id, sysCode } = this.$route.query
    this.isAddChildren = Number(isAddChildren)
    this.dataId = id

    if (sysCode) {
      this.detail.sysCode = sysCode
    }

    if (this.isAddChildren) {
      api.command.getDetail.call(this, {
        url: `/menu/${this.$route.query.id}`
      })
    }

    getAction('/api/app/list').then(result => {
      this.subordinate = result.data.map(e => {
        return {
          ...e,
          name: e.appName,
          value: e.appCode
        }
      })
    })

    this.getSvgFile()
  },
  methods: {
    getSvgFile() {
      let arr = require.context('../../../assets/icons/', true, /\.svg$/).keys()
      this.svgFileList = arr.map((e, i) => {
        let a = e.substring(e.indexOf('./') + 2, e.indexOf('.svg'))
        return {
          name: require(`@/assets/icons/${a}.svg?inline`).default,
          value: a
        }
      })
    },
    getForm1() {
      return {
        title: '菜单信息',
        type: 'cardForm',
        gutter: 15,
        data: [
          {
            name: '菜单名称',
            type: 'input',
            cols: 12,
            key: 'menuName',
            rules: [
              {
                required: true,
                type: 'string'
              }
            ]
          },
          {
            name: '菜单标题',
            type: 'input',
            cols: 6,
            key: 'title'
          },
          {
            name: '菜单副标题',
            type: 'input',
            cols: 6,
            key: 'subtitle'
          },
          {
            name: '显示排序',
            type: 'inputNumber',
            cols: 12,
            key: 'sort',
            props: {
              min: 0
            }
          },
          {
            name: '菜单类型',
            type: 'radioButton',
            cols: 6,
            key: 'type',
            defaultValue: '0',
            typeData: [
              {
                name: '菜单',
                value: '0'
              },
              {
                name: '按钮',
                value: '1'
              }
            ]
          },
          {
            name: '显示状态',
            type: 'radioButton',
            cols: 6,
            key: 'display',
            defaultValue: '0',
            typeData: [
              {
                name: '是',
                value: '0'
              },
              {
                name: '否',
                value: '1'
              }
            ]
          }
        ]
      }
    },

    getForm2() {
      return {
        title: '菜单设置',
        type: 'cardForm',
        gutter: 15,
        data: [
          {
            name: '所属系统',
            type: 'select',
            cols: 12,
            key: 'sysCode',
            typeData: this.subordinate,
            rules: [
              {
                required: true,
                type: 'string'
              }
            ]
          },
          {
            name: '权限标识',
            type: 'input',
            cols: 12,
            key: 'permission'
          },
          {
            name: '路由地址',
            type: 'input',
            cols: 12,
            key: 'path'
          },
          {
            name: '组件路径',
            type: 'input',
            cols: 12,
            key: 'component'
          },
          {
            name: '目标',
            type: 'input',
            cols: 12,
            key: 'target'
          },
          {
            name: '路由缓冲',
            type: 'radioButton',
            cols: 12,
            key: 'keepAlive',
            defaultValue: '0',
            typeData: [
              {
                name: '开启',
                value: '0'
              },
              {
                name: '关闭',
                value: '1'
              }
            ]
          },
          {
            name: '菜单颜色',
            type: 'input',
            cols: 12,
            key: 'color'
          },
          {
            name: '菜单css',
            type: 'input',
            cols: 12,
            key: 'css'
          }
        ]
      }
    },

    getForm3() {
      return {
        type: 'cardForm',
        title: '菜单图标',
        data: [
          {
            name: '菜单图标',
            type: 'dropDownSvg',
            cols: 6,
            key: 'icon',
            typeData: this.svgFileList
            // onChange: data => {
            //   this.detail.iconImg = this.imgIconObj[data.key]
            // }
          }
          //   {
          //     name: '菜单图标',
          //     type: 'upload',
          //     cols: 6,
          //     disabled: true,
          //     key: 'iconImg'
          //   }
        ]
      }
    },

    getFoot() {
      const left = [
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true,
          onClick: data => {
            console.log(data)
            data.icon = data.icon == 'icon' ? '' : data.icon

            if (!this.isAddChildren) {
              api.command.create
                .call(this, {
                  url: '/menu',
                  params: {
                    ...data,
                    parentId: this.dataId
                  },
                  isPost: false
                })
                .then(result => {
                  this.$router.push('/system/menu')
                })
            } else {
              api.command[this.dataId ? 'edit' : 'create']
                .call(this, {
                  url: '/menu',
                  params: {
                    ...data,
                    menuId: this.dataId
                  },
                  isPost: false
                })
                .then(result => {
                  this.$router.push('/system/menu')
                })
            }
          }
        },
        {
          name: '取消',
          type: 'default',
          onClick: () => {
            this.$router.push('/system/menu')
          }
        }
      ]

      return {
        left
      }
    }
  },
  render() {
    return (
      <DetailFormGroup
        foot={this.getFoot()}
        form={this.detail}
        data={[this.getForm1(), this.getForm2(), this.getForm3()]}
      />
    )
  }
}
</script>
<style lang="less" scoped>
.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

/deep/.ant-input[type='number'] {
  -moz-appearance: textfield;
}
/deep/.ant-input[type='number']::-webkit-inner-spin-button,
/deep/.ant-input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
